import { useEffect, useState, useRef } from "react";
import { inject, observer } from 'mobx-react';
import { useNavigate } from "react-router-dom";
import { Input, InputPassword, Button } from "../../components";
import { AuthService } from "../../services";
import { UserIcon } from '@heroicons/react/outline'
import moment from 'moment'
import Authentication from "../../Authentication";

const Singin = inject("authenStore", "commonStore")(observer((props) => {
  let authenStore = props.authenStore
  let commonStore = props['commonStore']
  const navigate = useNavigate();
  let { setCommonStore, getRememberMe } = commonStore
  let { getAccount, setCommonValue, getAccountAuth } = authenStore
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [signinMessage, setSigninMessage] = useState({
    success: true,
    message: ""
  })
  const inputRef: any = useRef(null);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus()
    if (getRememberMe.username !== "" && getRememberMe.password !== "") {
      setUsername(getRememberMe.username)
      setPassword(getRememberMe.password)
    }
  }, [])

  const onSignin = async () => {
    setSigninMessage({ ...signinMessage, success: true, message: '' })

    let errorMessage = ""

    if (username.trim() === "" && password.trim() === "")
      errorMessage = "โปรดระบุชื่อผู้ใช้งาน และรหัสผ่าน"
    else if (username.trim() === "")
      errorMessage = "โปรดระบุชื่อผู้ใช้งาน"
    else if (password.trim() === "")
      errorMessage = "โปรดระบุชื่อรหัสผ่าน"

    if (errorMessage !== "") {
      setSigninMessage({ ...signinMessage, success: false, message: errorMessage })
      return
    }

    setLoading(true)
    AuthService.login(username, password)
      .then((res: any) => {
        let { code, result, message, sys_message } = res?.data
        if (code === 200 && result) {
          result.timestamp = moment();
          setCommonValue({ accountAuth: result })
          navigate("/home-page")
        }
        else {
          setSigninMessage({ ...signinMessage, success: false, message: `${message} ${sys_message}` })
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        setSigninMessage({ ...signinMessage, success: false, message: `${err}` })
      })
  }

  return (
    <Authentication>
      <div className="flex min-h-screen">
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/Images/bg-signin.jpg"
            alt=""
          />
        </div>
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-white">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <img
                className="mx-auto h-44 w-auto"
                src={`/icons/Qlog-logo-login.png`}
              />
            </div>
            <div className="mt-6">
              <div className="space-y-4">

                <div>
                  <Input
                    onRef={(ref) => inputRef.current = ref}
                    label="ชื่อผู้ใช้งาน"
                    placeholder="ชื่อผู้ใช้งาน"
                    size="md"
                    defaultValue={username}
                    icon={<UserIcon className="w-5 h-5 text-gray-400" />}
                    iconPosition="start"
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyDown={(e) => { if (e.key === 'Enter') onSignin() }}
                  />
                </div>

                <div>
                  <InputPassword
                    label="รหัสผ่าน"
                    placeholder="รหัสผ่าน"
                    size="md"
                    defaultValue={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => { if (e.key === 'Enter') onSignin() }}
                  />
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      defaultChecked={(getRememberMe?.username !== "" && getRememberMe?.username !== "") ? true : false}
                      className="cursor-pointer h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                      onClick={(e: any) => {
                        if (username.trim() !== "" && password.trim() !== "" && e.target?.checked)
                          setCommonStore({ rememberMe: { username, password } })
                        else
                          setCommonStore({ rememberMe: { username: '', password: '' } })
                      }}
                    />
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900 dark:text-white">
                      จดจำผู้ใช้งาน
                    </label>
                  </div>

                </div>

                <div className={`rounded-md p-2 bg-danger-300 text-danger-800 text-sm ${signinMessage.success && 'sr-only'}`}>
                  {signinMessage.message}
                </div>

                <div >
                  <Button
                    text="เข้าสู่ระบบ"
                    type="primary"
                    width="w-full"
                    size="lg"
                    loading={loading}
                    onClick={() => onSignin()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Authentication>
  )
}))

export default (Singin)